import consola from 'consola'

interface CartFlieds {
  price: number
  scode: string
  img: string
  cat: string
  count: number
  name: string
  origin?: number // 来源 0:普通商品 1:食谱
  cookbookId?: string // 食谱id
}

export function useAddCart() {
  const submitLoading = ref<boolean>(false)

  const { formatQuery } = useUseBeforeRequest()

  const authStore = useAuthStore()
  const cartStore = useCartStore()
  const user = useState<_JwtPayload | null>('user', () => null)

  const userinfo = computed(() => {
    try {
      return user.value
    }
    catch (error) {
      consola.error(error)
      return authStore.userinfo
    }
  })

  const isLogin = computed(() => !!userinfo.value?.id)
  async function addCart(product: CartFlieds) {
    const formData = {
      scode: product.scode,
      count: product.count,
      img: product.img,
      price: Number(product.price),
      cat: product.cat,
      name: product.name,
      origin: product.origin || 0,
      cookbookId: product.cookbookId || undefined, // 食谱id
      customerId: authStore.userinfo?.id,
      custCode: authStore.userinfo?.custCode,
      mbType: authStore.userinfo?.mbType,
    }

    submitLoading.value = true

    if (!isLogin.value) {
      try {
        const { success, data, msg } = await useHttp.post('/api/cart/add', {
          ...formData,
          // 0:普通商品 2:预购商品 3:批发商品
          flag: product.cat.includes('WS') ? 3 : product.cat.includes('PO') ? 2 : 0,
        })

        if (success) {
          showNotify({
            message: msg,
            type: 'success',
          })
        }

        cartStore.addCart({
          ...data,
          goods: {
            ...product,
          },
        } as any)
      }
      finally {
        submitLoading.value = false
      }
      return
    }

    try {
      const { success, data, msg } = await useHttp.post<{
        img: string
        flag: number
        scode: string
        price: number
        origin: number
        cookbookId?: any
        name: string
        count: number
        id: number
      }>('/ichi_api/shoppingCart/addShoppingCartScode', null, {
        params: {
          MESSAGE: formatQuery(formData),
        },
      })

      if (success) {
        showNotify({
          message: msg?.replace(/<br\/>/g, '\n'),
          type: 'success',
        })

        cartStore.addCart({
          ...data,
          goods: {
            ...product,
          },
        } as any)
      }
    }
    catch (error: any) {
      showNotify({
        message: error.message || error.msg || error,
        type: 'error',
      })
    }
    finally {
      submitLoading.value = false
    }
  }

  return {
    submitLoading,
    addCart,
  }
}
